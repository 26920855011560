
import { defineComponent, onMounted, reactive, ref, onBeforeUnmount, createVNode } from 'vue'
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css'
import GC from '@grapecity/spread-sheets'
import { ssHotTop, handlerSheetsData , isMustfill, isNullRowSetBg} from './utils'
import {usetableHeight} from '../KufriPanelControl/utils'
import { getMappingData, saveNstMapping } from '@/API/sysParam'
import { NstMappingProps } from './types'
import blobDownload from '@/utils/blobDownload'
import '@/views/Budget/BudgetInput/licensen'
import { message } from 'ant-design-vue'
import { useRoute,  } from 'vue-router';
import { useStore } from 'vuex'
import {Modal} from "ant-design-vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
    name: '',
    setup() {
        const {tableHeight} = usetableHeight('#ssHost', '#ssHostvp')
        const { query } = useRoute();
        const versionValue = ref(query.version)
        const yearValue = ref(query.year)
        const make = useStore().state.make;
        const tableData = reactive<NstMappingProps[]>([])
        const count = ref(0)
        let workbook: GC.Spread.Sheets.Workbook
        const ssHotTopInit = () => {
            const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
            workbook = spread
            ssHotTop(spread, tableData)
        }
        const changeData = reactive<NstMappingProps[]>([])
        const initNstMapping = (type = '') => {
          const params = {
            version: versionValue.value,
            make,
            year: yearValue.value
          }
          getMappingData({params}).then(res => {
            count.value = res.count
            if (type !== 'AfterSave') {
              Object.assign(tableData, handlerSheetsData(res.data))
              ssHotTopInit()
            } else {
              Object.assign(changeData, handlerSheetsData(res.data))

              isNullRowSetBg(workbook, tableData)
            }
          })
        }

        // 更换成最新的id
        const updatedId = (data: NstMappingProps[]) => {
          if (changeData.length) {
            data.forEach((item, index) => {
              item.id = changeData[index].id
            })
          } 
          return data 
        }

        // 保存
        const handlerSave = () => {
          const data: NstMappingProps[] = []
          Object.assign(data, handlerSheetsData(tableData, false))
          Object.assign(data, updatedId(data))
          const params = {
            version: versionValue.value,
            make,
            year: yearValue.value,
            data
          }
          // 验证必填项是否填写
          if (isMustfill(data)) {
            saveNstMapping(params).then((res: any) => {
              if (!res.data || !res.data.length || res.data.length === 0) {
                Object.assign(tableData, handlerSheetsData(data))
                message.success('Save successfully!')
                // workbook.destroy();
                initNstMapping('AfterSave')
              } else {
                const errorList = res.data.map((item: string) => createVNode('div', {}, item))
                Modal.confirm({
                  title: 'Tips',
                  width: 500,
                  icon: createVNode(ExclamationCircleOutlined),
                  content: createVNode('div', {style: 'text-align: center; margin-left: -38px;'}, res.msg),
                  okText: 'Detail',
                  cancelText: 'Close',
                  onOk: () => {
                    Modal.info({
                      title: 'Tips',
                      width: 500,
                      icon: createVNode(ExclamationCircleOutlined),
                      content: createVNode('div', {style: 'text-align: center; margin-left: -38px;'}, errorList),
                      okText: 'Cancel'
                    });
                  }
                });
              }
            }).catch(() => {

              Object.assign(tableData, handlerSheetsData(data))
            })
          } else {
            Object.assign(tableData, handlerSheetsData(data))
            message.error('Brand ,Type Class, *IMPACT Code, *IMPACT Model Name为必填项')
          }
        }

        // 导出
        const handlerExport = () => {
          const config = {
              url: '/vapi/kufri/nstGroup/mapping/export',
              params: {
                  make,
                  version: versionValue.value,
                  year: yearValue.value,
              }
          }
          blobDownload(config)
        }
        onMounted(() => {
          // ssHotTopInit()
          initNstMapping()
        })

        onBeforeUnmount(() => {
            workbook.destroy();
        })
        return {
          tableHeight,
          handlerSave,
          handlerExport,
          count,
          versionValue,
          yearValue
        }

    }
 });
